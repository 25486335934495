@import ../../Utils/index
$bg: #121544
.Footer

    
    .cover
        +Padding(3rem 2rem)
        +Grid($gap: 1rem)
        +GTA('boxa boxb boxc boxd')
        +GTC(repeat(200px, 4))
        +BG($bg)
        +Color(white)
        +BoxShadow(-10px -10px 10px 0 lightgrey)

        li
            +Pointer
            +MarginBottom(.5rem)
            &:hover
                +Underline
        .header
            +Font(20px, $weight: 700)
        .boxa
            +GridArea(boxa)
            +Flex(null,null, 1rem, $flow: column,)
            +Padding(2rem)

            &>:nth-child(2)

            &>:nth-child(3)

                
        .boxb
            +GridArea(boxb)
            +Flex(null,null, 1rem, $flow: column,)
            +Padding(2rem)

        .boxc
            +GridArea(boxc)
            +Flex(null,null, 1rem, $flow: column,)
            +Padding(2rem)
        .boxd
            +Flex(null,null, 1rem, $flow: column,)
            +Padding(2rem)
            .social
                +Flex(null, null, 1rem)
                +MarginBottom(.5rem)
                .icon
                    +Font(20px)
                    +Transition(400ms)
                    +Pointer
                    &:hover
                        +Color(red)
                        +Transition(400ms)
            .wrapper
                +Flex($gap: 1rem, $flow: column)
                .contact
                    +Flex(null, center, .5rem)
                    .cicons
                        +Font(18px)
                    .ctext
                        +Transition(400ms)
                        +Pointer
                        &:hover
                            +Underline
                            +Color(red)
                            +Transition(400ms)

        @media (max-width: 1300px) 
            +GTA('boxa boxb' 'boxc boxd')
            +GTC(1fr 1fr)
            
        @media (max-width: 1000px) 
            +GTA('boxa' 'boxb' 'boxc' 'boxd')
            +Grid($gap: .5rem)
            +GTC(1fr)
            
    .copy
        +BG($bg, white)
        +Font(15px, $align: center)
        +Padding( 2rem)
        +BorderTop(2px, solid, white)