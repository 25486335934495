@import '../../Utils/index'

.Order
    +PaddingTop(5rem)
    @media (max-width: 500px)
        +PaddingTop(2rem)

    .cover
        +Grid($gap: 1rem, $align: center)
        +GTC(1fr 1fr 1fr)
        +GridItems(center)
        .wrapper
            +Flex($justify: center, $align: center, $flow: column, $gap: 1rem)
            .img
                +Font(100px)
                +Color(red)
                @media (max-width: 500px)
                    +Font(80px)

                        

            .text
                +Font(23px, black, null, 700)
                @media (max-width: 500px)
                    +Font(20px, black, null, 700)

        @media (max-width: 920px) 
            +GTC(1fr)
            +Grid($gap: 3rem, $align: center)
