
=Border($size: null, $color: null, $type: solid)
    border: $size $type $color

=NoBorder()
    border: none
    
=BorderRight($size: null, $type: solid, $color: null)
    border-right: $size $type $color

=BorderLeft($size: null, $type: solid, $color: null)
    border-left: $size $type $color

=BorderTop($size: null, $type: solid, $color: null)
    border-top: $size $type $color

=BorderBottom($size: null, $type: solid, $color: null)
    border-bottom: $size $type $color

    