=Font($size: 1rem, $color: null, $bg: null, $weight: 500, $lineHeight: null, $case: null, $style: normal, $align: null, $decoration: null)

    font-size: $size
    color: $color
    background: $bg
    font-weight: $weight
    line-height: $lineHeight
    text-transform: $case
    font-style: $style
    text-align: $align
    text-decoration: $decoration

=Color($color: null, $bg: null)
    color: $color
    background: $bg

=Color($color: null, $bg: null)
    color: $color
    background: $bg

=Underline()
    text-decoration: underline

=Spacing($letter: null, $word: null)
    letter-spacing: $letter
    word-spacing: $word

=TextCenter()
    text-align: center

=Pointer()
    cursor: pointer

=Opacity($opacity: 0)
    opacity: $opacity

=Transition($time: 100ms)
    transition: $time




=Pseudo($content: '', $height: null, $width: null, $position: absolute, $color: null, $bg: null)

    content: $content
    height: $height
    width: $width
    position: $position
    color: $color
    background: $bg

=CoverPseudo($content: '', $height: 100%, $width: 100%, $position: absolute, $color: null, $bg: null)
    content: $content
    height: $height
    width: $width
    position: $position
    color: $color
    background: $bg
    top: 0
    left: 0