=Grid($justify: null, $align: null,  $gap: null, $flow: row, $Cgap: null, $Rgap: null)

    display: grid

    justify-content: $justify
    align-content: $align

    grid-auto-flow: $flow

    gap: $gap
    column-gap: $Cgap
    row-gap: $Rgap

=GridItems( $justify: null, $align: null)
    justify-items: $justify
    align-items: $align

=GTA($areas)
    grid-template-areas: $areas

=GridArea($area)
    grid-area: $area

=GTC($gtc)
    grid-template-columns: $gtc

=GTR($gtr)
    grid-template-rows: $gtr

=GAC($gac)
    grid-auto-columns: $gac

=GAR($gar)
    grid-auto-rows: $gar

=GridCenter($gap: 0)
    display: grid
    justify-content: center
    align-content: center
    gap: $gap

=GridAlignSelf()
    align-self: center

=GridJustifySelf()
    justify-self: center