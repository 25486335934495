*
    padding: 0
    margin: 0
    box-sizing: border-box

h1,h2,h3,h4,h5,h6
    padding: 0
    margin: 0
    
a, a:hover, a:visited
    text-decoration: none
    list-style: none
