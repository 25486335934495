@import '../../Utils/index'

.About
    +PaddingBlock(3rem 2rem)
    +Color($textcolor)
    .cover
        +Grid(null, null, 1rem)
        +GTC(1fr 1fr)
        .left
            @media (max-width: 1000px)
                +SetHeight(200px)

        .right
            +Flex($flow: column, $gap: 1.5rem)
            +PaddingInline(1rem)
            .header
                +Font(20px, null, null, 800)                

            .longtext
                +Font($lineHeight: 1.5rem)

            .wrapper
                +Flex($gap: 1.5rem)
                +MarginRight(1.5rem)
                @media (max-width: 1000px)
                    +Flex($gap: .5rem)
                    +MarginRight(.5rem)
                .item
                    +BorderRight(3px, solid, red)
                    +Pointer
                    +PaddingRight(1.5rem)
                    +Font($weight: 600)
                    +Transition(500ms)
                    @media (max-width: 1000px)
                        +PaddingRight(0.5rem)
                        +Font(15px)
                    @media (max-width: 500px)
                        +NoBorder()
                        +Font(14px)

                .iteml
                    +Font($weight: 600)
                    +Transition(500ms)
                    +Pointer
                    @media (max-width: 1000px)
                        +Font(15px)
                    @media (max-width: 500px)
                        +Font(14px)

            .shorttext
                +Font($lineHeight: 1.5rem)
                +Transition(500ms)
            .button
                +Padding(.7rem 1.5rem)
                +Radius(.3rem)
                +FitWidth()
                +Background(red, whitesmoke)
                +Border(3px, solid, red)
                +Transition(500ms)
                +Pointer
                @media (max-width: 500px)
                    +FlexAlignSelf(center)

                
                &:hover
                    +Background(white, red)
                    +Transition(500ms)
            

        @media (max-width: 1000px) 
            +GTC(1fr)
        @media (max-width: 500px) 
            text-align: center
    @media (max-width: 1000px) 
        +PaddingBlock(3rem 1rem)
