@import '../../Utils/index'

.Title
    .contain
        +Flex(center, center, 1rem)
        +Padding(2rem 1rem 4rem)
        +Font(30px, null,null, 800)
        text-shadow: 1px 2px grey
        @media (max-width: 1000px) 
            +Padding(2rem 1rem 2rem)
        .line
            +Height(5px)
            +Width(20px)
            +Radius(40px)
            +BG(red)
            +TextCenter
        .one
            +Color(red)
        .two
            +Color($textcolor)

