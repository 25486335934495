=Radius($radius: 50%)
    border-radius: $radius
    
=Circle($height, $width, $bg: null)
    border-radius: 50%
    height: $height
    width: $width
    background: $bg

=RightRadius($right: null)
    border-radius: 0 $right $right 0 

=LeftRadius($left: null)
    border-radius: $left 0 0 $left

=TopRadius($top: null)
    border-radius: $top $top 0 0

=BottomRadius($bottom: null)
    border-radius: 0 0 $bottom $bottom

=SetRadius($top: null, $right: null, $bottom: null, $left: null)
    border-radius: $top $right $bottom $left