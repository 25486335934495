@import ../../Utils/index



.Expert
    +Padding(3rem 1rem)
    .cover
        +Grid(null, null, 1rem)
        +Color($textcolor)
        +GTC(1fr 1fr 1fr)
        .wrapper
            +Flex($flow: column,$align: center, $gap: .3rem)
            +PaddingInline(2rem)
            +TextCenter
            &>:nth-child(4)
                +Font(16px, null, null, 700)
                +MarginTop(.2rem)
                
            .circle
                +Circle(50px, 50px, red)
                +MarginBottom(1rem)
            .name
                +Font(20px, $textcolor, $weight: bold)
            .text
        @media (max-width: 995px) 
            +Grid(null, null, 3rem)
            +GTC(1fr)

        