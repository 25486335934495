*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border: none;
    outline: none;
    text-decoration: none;
    list-style: none;
}

body{
    background: rgb(248, 239, 239);
    font-family: sans-serif;
    margin: auto;
    width: min(100%, 2000px)
}
html{
    line-height: 1.5;
}

.active{
    color: red !important;
}