@import '../../Utils/index'

.Card
    .cover
        +Flex($gap: 1.5rem, $justify: center)
        +Padding(0 2rem)
        .card
            +Flex($flow: column, $gap: .75rem)
            +Radius(.3rem)
            +Border(5px, solid, red)
            +Padding(2rem) 
            scroll-snap-align: start
            +MinWidth(max-content)
            +FitWidth()
            +Pointer
            +Transition(500ms ease-in-out)
            +Background(transparent)
            +BoxShadow(5px 5px 10px 0 grey)
            +Color($textcolor)
            +Transition(500ms ease-in-out)
            &:hover
                +Background( $cardcolor)
                +BoxShadow(3px 3px 7px 0 black)
                +Color(white)
                
            &>:nth-child(1)
                +Font(30px)
            .plan
                +Font(15px, $case: uppercase, $weight: 700)
            .price
                +Font(40px, $weight: 800)
            .content
                +Flex($flow: column, $gap: .5rem, $justify: center)
                .wrapper
                    +Flex($justify: flex-start, $align: center, $gap: .5rem)
                    .check
                        +Font(17px)
                    .text
            .canopy
                +Flex(flex-start, center, .5rem)
                .benefit
                .arrow
            .btn
                +SetHeight(53px, $bg: white)
                +FlexCenter()
                +Pointer
                +Font($weight: bold)
                +Color(red)
                +BoxShadow(5px 5px 5px 0 lightgrey)
                +Transition(500ms)
                +Radius(.2rem)
                &:hover
                    +Transition(500ms)
                    +Color(red, white)
                    +BoxShadow(2px 2px 5px 0 grey)
                    +Radius(.2rem)

        @media (max-width: 1500px) 
            overflow-x: auto 
            justify-content: start
            scroll-padding-inline: 1rem 
            scroll-snap-type: inline mandatory