
=Image()
    height: 100%
    width: 100%
    object-fit: cover
    
=BackgroundImage($url)
    background: url($url)
    background-position: top
    background-repeat: no-repeat
    background-size: cover
    
=Background($bg: null, $color: null)
    background-color: $bg
    color: $color
    
=BG($bg: null, $color: null)
    background-color: $bg
    color: $color

=LinearGradient($gradient)
    background-image: linear-gradient($gradient) 