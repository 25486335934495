@import '../../Utils/index'
.Nav
    +Sticky()
    +Background($bgColor)
    +BoxShadow(2px 2px 10px 0 lightgrey)
    .mobile
        +NoDisplay
        @media (max-width: 1100px)
            +Block
    nav
        +Grid($gap: 1rem)
        +GTC(1fr 3fr 1fr)
        +SetHeight(100px)
        @media (max-width: 1100px)
            +NoDisplay

        .left
            +FlexCenter()
            +Font(1.5rem, red)
        .middle
            +FlexCenter()
            +Font(15px, null, 700)
            ul
                +Flex($justify: space-around)
                +SetHeight()
                li
                    +Pointer()
                    +Transition(500ms)
                    &:hover
                        +Transition(500ms)
                        +Color(red)
                    a
                        +Font(15px, $weight: 600, $color: black)
                        +Transition(500ms)
                        +Relative()
                        +Pointer()
                        &:hover
                            +Transition(500ms)
                            +Color(red)
                        &::after
                            +Pseudo()
                            +Circle(10px, 10px, $bgColor)
                            +SetVertical(250%)
                            +Transition(500ms)
                        &.active::after
                            +Pseudo()
                            +Transition(500ms)
                            +Color(red)
                            +Circle(10px, 10px, red)
                            +SetVertical(150%)
                .list
                    +FlexCenter($gap: .1rem)
                    &:hover
        .right
            +Flex($justify: flex-end, $align: center, $gap: 2.5rem)
            +PaddingInline(1rem)
            .icon
                +Font(20px)
                +Pointer()
                &:hover
                    +Color(red)
            .wrapper
                +Flex($align: center, $gap: .4rem)
                +Padding(.6rem 1rem, red)
                +Font(15px, $weight: bold)
                +Border(3px, solid, red)
                +Color(white)
                +Border(2px, red)
                +Transition(500ms)
                +Radius(2rem)
                +BoxShadow(2px 2px 10px 0 lightgrey)
                +Pointer()
                &:hover
                    +Transition(500ms)
                    +BG(white, red)
                    +Border(3px, red)
                .login-icon
                    +Font(1.5rem)
                span
        
