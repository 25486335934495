=AspectRatio()
    width: 100%
    aspect-ratio: 16 / 9

=FullViewport($bg: null)
    height: 100vh
    width: 100vw
    background: $bg

=FullPercentage($bg: null)
    height: 100%
    width: 100%
    background: $bg

=SetHeight($height: null, $bg: null)
    height: $height
    width: 100%
    background: $bg

=SetWidth($width: null, $bg: null)
    width: $width
    height: 100%
    background: $bg

=SetHeightWidth($height: null, $width: null, $bg: null)
    height: $height
    width: $width
    background: $bg
    
=Width($width)
    width: $width

=MaxWidth($width)
    max-width: $width

=MinWidth($width)
    min-width: $width

=Height($height)
    height: $height

=MaxHeight($height)
    max-height: $height

=MinHeight($height)
    min-height: $height
    
=FitWidth()
    width: fit-content

=FitHeight()
    height: fit-content