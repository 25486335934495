@import '../../Utils/index'

.Header
    +Padding(11rem 1rem)
    +BackgroundImage('../../Images/bg.jpg')
    .cover
        +FlexCenter($flow: column, $gap: 3rem)
        .big
            +TextCenter()
            +Font(55px, $textcolor, null, 800, $case: capitalize)
            +Width(20ch)
            @media (max-width: 1000px) 
                +Font(35px, black, null, 700, $case: capitalize)
            @media (max-width: 500px) 
                +Font(27px, black, null, 700, $case: capitalize)
            .clr
                +Color(red)

        .small
            +Button($size: 3px, $padding: 1rem 2.5rem, $bg: red, $textcolor: white, $bordercolor: red)
            +Font(20px, $weight: 700)
            +Transition(500ms)
            +Radius(2rem)
            +Pointer
            @media (max-width: 1000px) 
                +Button($size: 3px, $padding: .6rem 2rem, $bg: red, $textcolor: white, $bordercolor: red)
            @media (max-width: 500px) 
                +Button($size: 3px, $padding: .5rem 2rem, $bg: red, $textcolor: white, $bordercolor: red)

            &:hover
                +Background(white, red)
                +Transition(500ms)