=Position($top: null, $left: null, $bottom: null, $right: null)
    top: $top
    left: $left
    bottom: $bottom
    right: $right

=Relative($top: null, $left: null, $bottom: null, $right: null)
    position: relative
    margin-top: $top
    margin-left: $left
    margin-bottom: $bottom
    margin-right: $right

=Absolute($top: null, $left: null, $bottom: null, $right: null)
    position: absolute
    top: $top
    left: $left
    bottom: $bottom
    right: $right


=Fixed($top: null, $left: null, $bottom: null, $right: null)
    position: fixed
    top: $top
    left: $left
    bottom: $bottom
    right: $right


=Sticky($top: 0, $left: null, $bottom: null, $right: null)
    position: sticky
    top: $top
    left: $left
    bottom: $bottom
    right: $right


=Static($top: null, $left: null, $bottom: null, $right: null)
    position: relative
    top: $top
    left: $left
    bottom: $bottom
    right: $right


=Center()
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

=SetVertical($top: 0, $bottom: null)
    top: $top
    $bottom: $bottom
    left: 50%
    transform: translateX(-50%)

=SetHorizontal($left: 0, $right: null)
    top: 50%
    left: $left
    right: $right
    transform: translateY(-50%)
