=Flex($justify: null, $align: null, $gap: null, $flow: row, $Cgap: null, $Rgap: null, $wrap: null)

    display: flex
    
    justify-content: $justify
    align-items: $align

    flex-direction: $flow

    gap: $gap
    column-gap: $Cgap
    row-gap: $Rgap

    flex-wrap: $wrap

=FlexItems( $justify, $align)
    justify-items: $justify
    align-items: $align

=FlexCenter($gap: 0, $flow: null)
    display: flex
    justify-content: center
    align-items: center
    gap: $gap
    flex-direction: $flow

=FlexJustifySelf($justify)
    justify-self: $justify

=NoDisplay()
    display: none
=Block()
    display: block

=FlexAlignSelf($align)
    align-self: $align