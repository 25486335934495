=TranslateCenter($translate)
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    
=Translate($top: null, $left: null, $bottom: null, $right: null)
    top: $top
    left: $left
    bottom: $bottom
    right: $right
    transform: translate($top, $left)


=TranslateX($translate)
    transform: translateX($translate)
    
=TranslateY($translate)
    transform: translateY($translate)