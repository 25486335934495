=Padding($padding, $bg: null)
    padding: $padding
    background: $bg


=PaddingInline($inline, $bg: null)
    padding-inline: $inline
    background: $bg

=PaddingBlock($block, $bg: null)
    padding-block: $block
    background: $bg
    
=PaddingTop($top, $bg: null)
    padding-top: $top
    background: $bg

=PaddingBottom($bottom, $bg: null)
    padding-bottom: $bottom
    background: $bg
    
=PaddingLeft($left, $bg: null)
    padding-left: $left
    background: $bg
    
=PaddingRight($right, $bg: null)
    padding-right: $right
    background: $bg
    
=BoxShadow($shadow: null)
    box-shadow: $shadow