=Margin($margin, $bg: null)
    margin: $margin
    background: $bg

=MarginInline($inline)
    margin-inline: $inline

=MarginBlock($block)
    margin-block: $block
    
=MarginTop($top)
    margin-top: $top

=MarginBottom($bottom)
    margin-bottom: $bottom
    
=MarginLeft($left)
    margin-left: $left
    
=MarginRight($right)
    margin-right: $right
