@import ../../Utils/index

$bg: #121544


.Mobile
    .cover
        .top
            +SetHeight(60px, $bgColor)
            +Fixed($top: 0)
            +Flex(space-between, center)
            +BoxShadow(2px 2px 10px 0 lightgrey)
            +Padding(0 1rem)
            .logo
                +Font(25px, red, null, 700)
            .icon
                +Font(25px, black, null, 800)
                +Pointer
        #top
            +SetHeight(60px)
        .bottom
            +SetHeight(calc(100vh - 60px), $bgColor)
            +TranslateX(200vw)
            +Transition(500ms)
            +Fixed($top: 60px)
            +Flex($align: null, $flow: column, $gap: 1rem)
            .wrapper
                +Grid($align: center)
                +GTC(30px 1fr)
                +PaddingInline(1rem)
                input
                    +Background(transparent)
            ::placeholer
                +Color(black)


                .search

            ul
                +Flex($align: center, $flow: column, $gap: 1rem)
                a
                    color: black
